
import Axios from "@/core/services/Http";
import {defineComponent, inject, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import i18n from "@/core/plugins/i18n";
import Notify from "@/core/services/Notify";
export default defineComponent({
  setup() {
    const route = useRoute();
    const appInstance: any = inject("instance");
    const store = useStore();
    const router = useRouter();
    const permission_error = ref(false);

    const check_token = async () => {
      try {
        localStorage.setItem('state',route.query.state as any);
        localStorage.setItem('code',route.query.code as any);

        await Axios.post('/auth/authorization/token', {
          "code": route.query.code,
          'state': route.query.state,
          'callback_origin': 'admin',
          "client_id": process.env.VUE_APP_CLIENT_ID
        }).then((data) => {
            localStorage.setItem('token', data?.data.token);
            const abilities = async() => {
              const response = await store.dispatch("abilities", appInstance);
              localStorage.removeItem('session-timestamp');
              if (response && !response?.data.roles.admin) {
                localStorage.removeItem("token");
                permission_error.value = true;
                setTimeout(() => {
                  window.location.href = process.env.VUE_APP_GEP_SHOP
                }, 3000);
              }
              else
              {
                router.push({ name: "dashboard" });
              }
            }
            abilities();
        }).catch(e => {
          if (e?.message.includes('401'))
          {
            appInstance.config.globalProperties.$notify.error({
              title: "Error",
              message: i18n.global.t("message.UNAUTHORIZED_USER")
            });
          }
          localStorage.removeItem("token");
          localStorage.removeItem("state");
          localStorage.removeItem("code");
          permission_error.value = true;
          setTimeout(() => {
            window.location.href = process.env.VUE_APP_GEP_SHOP
          },3000);
        });
      }
      catch (e)
      {
        if (e?.message.includes('401'))
        {
          appInstance.config.globalProperties.$notify.error({
            title: "Error",
            message: i18n.global.t("message.UNAUTHORIZED_USER")
          });
        }
        localStorage.removeItem("token");
        localStorage.removeItem("state");
        localStorage.removeItem("code");
        permission_error.value = true;
        setTimeout(() => {
          window.location.href = process.env.VUE_APP_GEP_SHOP
        },3000);
      }
    }

    if (localStorage.getItem('token') == null) {
      check_token()
    }

    return {
      permission_error
    }
  }
});
